import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase.config";

const getPlaceListApi = httpsCallable(functions, "getPlaceList");

type GetPlaceListType = {
  lat: string;
  lng: string;
  type: string;
  radius: string;
};

export const getPlaceList = async (data: GetPlaceListType) => {
  try {
    const response: any = await getPlaceListApi(data);
    console.log("Place list RESPONSE", response);
    return response.data;
  } catch (error) {
    console.log("ERROR", error);
    return undefined;
  }
};
