import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from "firebase/functions";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCunzW_wcnrsAmge4bn73RpAlXKOMDwK4A",
  authDomain: "closebyai.firebaseapp.com",
  projectId: "closebyai",
  storageBucket: "closebyai.appspot.com",
  messagingSenderId: "205068492851",
  appId: "1:205068492851:web:7f742a3f085a2c04284346",
  measurementId: "G-KCJSYHH54V",
};

const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { functions, analytics, auth, db, storage };
