import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { LocationType, emptyLocation } from "../typings/LocationType";

const initialState: LocationType = emptyLocation;

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    createLocation(state, action) {
      state = action.payload;
    },

    updateLocation(state, action) {
      Object.keys(action.payload).forEach((key) => {
        state[key as keyof LocationType] = action.payload[key];
      });
    },

    deleteLocation(state) {
      state = {};
    },
  },
});

export const { createLocation, updateLocation, deleteLocation } =
  locationSlice.actions;

export const locationState = (state: RootState) => state.location;

export default locationSlice.reducer;
