import { createSlice } from "@reduxjs/toolkit";
import { AuthType } from "../typings/AuthType";
import { RootState } from "./store";

const initialState: AuthType = {
  uid: null,
  email: null,
  displayName: null,
  photoUrl: null,
  authIsReady: false,
  admin: false,
  site: false,
  all: 0,
  recent: 0,
  premium: false,
  start: 0,
  end: 0,
  username: null,
  profilePhoto: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, action) {
      state.uid = action.payload.uid;
      state.email = action.payload.email;
      state.displayName = action.payload?.displayName || null;
      state.photoUrl = action.payload?.photoUrl || null;
    },
    admin(state, action) {
      state.admin = action.payload;
    },
    premium(state, action) {
      state.premium = action.payload;
    },
    subscription(state, action) {
      state.start = action.payload.start;
      state.end = action.payload.end;
    },
    logout(state) {
      state.uid = null;
      state.email = null;
      state.displayName = null;
      state.photoUrl = null;
      state.admin = false;
      state.premium = false;
      state.all = 0;
      state.recent = 0;
      state.start = 0;
      state.end = 0;
    },
    authReady(state, action) {
      state.uid = action.payload.uid;
      state.email = action.payload.email;
      state.displayName = action.payload.displayName || null;
      state.photoUrl = action.payload.photoUrl || null;
      state.authIsReady = true;
    },
    site(state, action) {
      state.site = action.payload;
    },
    count(state, action) {
      state.all = action.payload.all;
      state.recent = action.payload.recent;
    },
  },
});

export const {
  login,
  admin,
  premium,
  subscription,
  logout,
  authReady,
  site,
  count,
} = authSlice.actions;

export const authState = (state: RootState) => state.auth;
export default authSlice.reducer;
