export type LocationType = {
  lat?: string;
  lng?: string;
  fullAddress?: string; // address, city, state, zip, country
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  placeName?: string;
  placeDescription?: string;
  placeDetails?: string;
  around?: string;
};

export const emptyLocation: LocationType = {
  lat: "",
  lng: "",
  fullAddress: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  country: "",
  placeName: "",
  placeDescription: "",
  placeDetails: "",
  around: "",
};
