import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { locationState, updateLocation } from "../redux/locationSlice";
import { getPlaceList } from "../utils/getPlaceList";

export default function PlaceList() {
  const [loading, setLoading] = useState<boolean>(false);
  const [started, setStarted] = useState<boolean>(false);
  const location = useSelector(locationState);
  const dispatch = useDispatch();

  const fetchPlaceList = async () => {
    setLoading(true);
    setStarted(true);

    console.log("getting suggestions for", location.fullAddress);

    const response = await getPlaceList({
      lat: location.lat || "",
      lng: location.lng || "",
      radius: "500",
      type: "point_of_interest",
    });
    console.log("response =======", response);
  };

  return (
    <div>
      <button
        onClick={fetchPlaceList}
        disabled={loading}
        className="px-3 py-2 text-white bg-blue-500 rounded-md disabled:opacity-30"
      >
        Get Place List
      </button>
      {started && (
        <div>
          {loading ? (
            <p>Fetching place information...</p>
          ) : location.around ? (
            <div className="whitespace-pre-wrap">{location.around}</div>
          ) : (
            <div>Nothing found</div>
          )}
        </div>
      )}
    </div>
  );
}
