import { functions } from "../firebase.config";
import { httpsCallable } from "firebase/functions";
import { ApiMessageType } from "../typings/ApiMessageType";

const processChatApi = httpsCallable(functions, "apiChat");

type ProcessChatType = {
  messages: ApiMessageType[];
};

export const processChat = async (data: ProcessChatType) => {
  try {
    const response: any = await processChatApi(data);
    console.log("Process chat RESPONSE", response);
    return response.data.message;
  } catch (error) {
    console.log("ERROR", error);
    return undefined;
  }
};
