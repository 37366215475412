import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { locationState, updateLocation } from "../redux/locationSlice";
import { ApiMessageType } from "../typings/ApiMessageType";
import { processChat } from "../utils/processChat";

export default function PlaceDetails() {
  const [loading, setLoading] = useState<boolean>(false);
  const [started, setStarted] = useState<boolean>(false);
  const location = useSelector(locationState);
  const dispatch = useDispatch();

  const getSuggestions = async () => {
    setLoading(true);
    setStarted(true);
    console.log("getting suggestions for", location.fullAddress);

    try {
      if (!location.fullAddress) throw new Error("No address found");
      let systemPrompt =
        "Respond as a world-class tourguide. " +
        "The user will give you a formatted address. " +
        "You will list points of interest near the address. " +
        "For each point of interest, you will provide the place name, coordinates, description, and a history. ";

      let userPrompt = location.fullAddress;

      let messages: ApiMessageType[] = [
        {
          role: "system",
          content: systemPrompt,
        },
        {
          role: "user",
          content: userPrompt,
        },
      ];

      console.log("MESSAGES", messages);

      const response = await processChat({ messages });
      if (!response) throw new Error("No response");
      dispatch(updateLocation({ around: response }));
      setLoading(false);
    } catch (error) {
      dispatch(updateLocation({ around: "" }));
      setLoading(false);
    }
  };

  return (
    <div>
      <button
        onClick={getSuggestions}
        disabled={loading}
        className="px-3 py-2 text-white bg-blue-500 rounded-md disabled:opacity-30"
      >
        Get Place Details
      </button>
      {started && (
        <div>
          {loading ? (
            <p>Fetching place information...</p>
          ) : location.around ? (
            <div className="whitespace-pre-wrap">{location.around}</div>
          ) : (
            <div>Nothing found</div>
          )}
        </div>
      )}
    </div>
  );
}
