import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase.config";

const getPlaceDetailsApi = httpsCallable(functions, "getPlaceInfo");

type GetPlaceDetailsType = {
  lat: string;
  lng: string;
};

export const getPlaceDetails = async (data: GetPlaceDetailsType) => {
  try {
    const response: any = await getPlaceDetailsApi(data);
    console.log("Place details RESPONSE", response);
    return response.data;
  } catch (error) {
    console.log("ERROR", error);
    return undefined;
  }
};
