import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { locationState, updateLocation } from "../redux/locationSlice";

export default function GeoLocation() {
  const location = useSelector(locationState);
  const dispatch = useDispatch();

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          dispatch(
            updateLocation({
              lat: String(position.coords.latitude),
              lng: String(position.coords.longitude),
            })
          );
        },
        (error) => {
          console.error(error);
        },
        { enableHighAccuracy: true }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, [dispatch]);

  return (
    <div>
      {location.lat && location.lng ? (
        <p>
          Latitude: {location.lat}
          <br />
          Longitude: {location.lng}
        </p>
      ) : (
        <p>Fetching location...</p>
      )}
    </div>
  );
}
