import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { ChatType } from "../typings/ChatType";

const initialState: { chats: ChatType[] } = { chats: [] };

const chatlistSlice = createSlice({
  name: "chatlist",
  initialState,
  reducers: {
    updateChatlist(state, action) {
      state.chats = action.payload;
    },
  },
});

export const { updateChatlist } = chatlistSlice.actions;

export const chatlistState = (state: RootState) => state.chatlist.chats;

export default chatlistSlice.reducer;
