import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { locationState, updateLocation } from "../redux/locationSlice";
import { getPlaceDetails } from "../utils/getPlaceDetails";

export default function PlaceComponent() {
  const location = useSelector(locationState);

  const dispatch = useDispatch();
  useEffect(() => {
    const fetchPlaceDetails = async () => {
      console.log("location in useEffect", location.lat, location.lng);

      const response = await getPlaceDetails({
        lat: location.lat || "",
        lng: location.lng || "",
      });
      console.log("response =======", response);

      if (response) {
        dispatch(updateLocation({ fullAddress: response.address }));
      } else {
        console.log("error, not found");
      }
    };
    if (location.lat && location.lng) fetchPlaceDetails();
  }, [dispatch, location]);

  return (
    <div>
      {location.fullAddress ? (
        <p>Full Address: {location.fullAddress}</p>
      ) : (
        <p>Fetching place information...</p>
      )}
    </div>
  );
}
