import { useDispatch, useSelector } from "react-redux";
import GeoLocation from "./components/GeoLocation";
import PlaceComponent from "./components/PlaceComponent";
import { locationState } from "./redux/locationSlice";
import { useEffect, useState } from "react";
import { authState, site } from "./redux/authSlice";
import { httpsCallable } from "firebase/functions";
import { functions } from "./firebase.config";
import PlaceDetails from "./components/PlaceDetails";
import PlaceList from "./components/PlaceList";

export default function App() {
  const location = useSelector(locationState);
  const [sitePassword, setSitePassword] = useState("");
  const dispatch = useDispatch();
  const state = useSelector(authState);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isAuthorized = await httpsCallable(
      functions,
      "checkPassword"
    )(sitePassword);

    dispatch(site(isAuthorized.data));
    if (isAuthorized) {
      window.localStorage.setItem("closebyPassed", "True");
    }
  };

  useEffect(() => {
    if (window.localStorage.getItem("closebyPassed")) {
      dispatch(site(true));
    }
  }, [dispatch]);

  if (!state.site) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <form onSubmit={handleSubmit}>
          <input
            className="px-3 py-2 border rounded-md outline-none"
            type="password"
            placeholder="Enter Password"
            onChange={(e) => setSitePassword(e.target.value)}
          />
        </form>
      </div>
    );
  }

  return (
    <div className="flex flex-col p-2 space-y-2">
      <div className="text-xl">CloseBy.ai</div>
      <div>
        <GeoLocation />
        {location.lat && location.lng && <PlaceComponent />}
        {location.lat && location.lng && <PlaceList />}
        {location.fullAddress && <PlaceDetails />}
      </div>
    </div>
  );
}
